import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import img_cyrille from '../assets/img_cyrille.jpeg';
import ScrollItem from './ScrollItem';
import aws from '../assets/aws.png';
import cisco from '../assets/cisco.png';
import microsoft from '../assets/microsoft.png';
import piwi from '../assets/piwi.png';
import redhat from '../assets/redhat.png';
import ciena from '../assets/ciena.png';
import huawei from '../assets/huawei.png';
import ericsson from '../assets/ericsson.png';
import nokia from '../assets/nokia.png';
import edf from '../assets/edf.png';
import orange from '../assets/orange.png';
import bouygues from '../assets/bouygues.png';
import sfr from '../assets/sfr.png';
import sncf from '../assets/sncf.png';
import ratp from '../assets/ratp.png';

const equipementier = [
    { id: 1, src: ericsson, alt: 'ericsson' },
    { id: 2, src: cisco, alt: 'cisco' },
    { id: 3, src: nokia, alt: 'nokia' },
    { id: 4, src: huawei, alt: 'huawei' },
    { id: 5, src: ciena, alt: 'ciena' },
];

const stack = [
    { id: 1, src: aws, alt: 'aws' },
    { id: 2, src: microsoft, alt: 'microsoft' },
    { id: 3, src: redhat, alt: 'redhat' },
    { id: 4, src: piwi, alt: 'piwi' },
];

const entreprises = [
    { id: 1, src: edf, alt: 'edf' },
    { id: 2, src: orange, alt: 'orange' },
    { id: 3, src: bouygues, alt: 'bouygues' },
    { id: 4, src: sfr, alt: 'sfr' },
    { id: 5, src: sncf, alt: 'sncf' },
    { id: 6, src: ratp, alt: 'ratp' },
];

export default function About() {
    return (
        <Box
            width="full"
            className="p-20"
            sx={{
                textAlign: 'center',
            }}
            id="about"
        >
            <Container maxWidth="md">
                {/* Affiche l'image au-dessus du paragraphe pour les écrans <= 720px */}
                <Box
                    display="flex"
                    flexDirection={{ xs: 'column', md: 'row' }}
                    alignItems="center"
                    justifyContent="center"
                >
                    <img
                        src={img_cyrille}
                        alt="president de enou consulting"
                        className="w-auto h-auto rounded-t-full"
                    />
                    <Typography variant="h2" className="text-3xl font-bold text-center my-10">
                        Qui sommes-nous ?
                    </Typography>
                </Box>

                {/* Paragraphe */}
                <Typography variant="body1" className="mt-10 text-center text-lg">
                    Bienvenue chez E-NOU CONSULTING, votre partenaire stratégique dans le monde en
                    évolution rapide des télécommunications. Avec une expertise inégalée et une
                    passion pour l'innovation, nous nous engageons à fournir des solutions de
                    consulting sur mesure qui répondent aux défis uniques de nos clients. Fondée
                    par une équipe d'experts visionnaires, dont notre président, NOUWE Cyrille,
                    E-NOU CONSULTING s'est rapidement établie comme une force motrice dans le
                    secteur, aidant les entreprises à naviguer dans le paysage complexe des
                    technologies de l'information et de la communication. Notre mission est de
                    transformer les obstacles en opportunités, en utilisant notre savoir-faire
                    technique et stratégique pour permettre à nos clients de se connecter, de
                    communiquer et de réussir dans l'ère numérique. Chez E-NOU CONSULTING, nous
                    croyons en la création de valeur durable et en l'établissement de relations
                    fondées sur la confiance, l'excellence et l'innovation. Découvrez comment nous
                    pouvons vous aider à atteindre vos objectifs avec des solutions qui dépassent
                    les attentes.
                </Typography>
            </Container>

            {/* ScrollItems */}
            <ScrollItem title="Nos entreprises" items={entreprises} />
            <ScrollItem title="Nos équipementiers" items={equipementier} />
            <ScrollItem title="Nos technologies d'expertise" items={stack} />
        </Box>
    );
}

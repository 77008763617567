import React, { useEffect, useRef, useState } from 'react'
import { Box, IconButton, Typography } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';


export default function ScrollItem(
  {title, items}: {title: string, items: {id: number, src: string, alt: string}[]}
) {
    const carouselRef = useRef<HTMLDivElement>(null);
    const [currentSlide, setCurrentSlide] = useState(0);
  
    const scrollTo = (index: number) => {
        if (carouselRef.current) {
          const scrollAmount = carouselRef.current.clientWidth * index + 20 * index;
          carouselRef.current.scrollTo({ left: scrollAmount, behavior: 'smooth' });
          setCurrentSlide(index);
        }
      };
    
      const scrollLeft = () => {
        const newSlide = (currentSlide - 1 + items.length) % items.length;
        scrollTo(newSlide);
      };
    
      const scrollRight = () => {
        const newSlide = (currentSlide + 1) % items.length;
        scrollTo(newSlide);
      };
    
      useEffect(() => {
        const interval = setInterval(scrollRight, 2000);
        return () => clearInterval(interval);
      }, [currentSlide]);
    
    return (
        <Box className='mt-14'>
            <Typography variant="h4" className="text-3xl font-bold text-center p-3 uppercase">{ title }</Typography>
            <Box className="relative flex items-center justify-center w-full h-64 bg-gray-100">
            <IconButton
                onClick={scrollLeft}
                sx={{
                position: 'absolute',
                left: '0',
                zIndex: 1,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                color: 'white',
                }}
            >
                <ChevronLeft />
            </IconButton>
            <Box
                ref={carouselRef}
                className="flex overflow-x-auto scroll-smooth"
                sx={{
                '&::-webkit-scrollbar': { display: 'none' },
                }}
            >
                {items.map((item) => (
                <Box
                    key={item.id}
                    className="flex-shrink-0 w-64 h-60 p-2"
                >
                    <img
                    src={item.src}
                    alt={item.alt}
                    className="object-contain w-full h-full"
                    />
                </Box>
                ))}
            </Box>
            <IconButton
                onClick={scrollRight}
                sx={{
                position: 'absolute',
                right: '0',
                zIndex: 1,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                color: 'white',
                }}
            >
                <ChevronRight />
            </IconButton>
        </Box>
    </Box>
  );
};
import React, { useRef } from 'react'
import { TextField, Button, Box, CircularProgress } from '@mui/material';
import emailjs from '@emailjs/browser';
import { green } from '@mui/material/colors';

export default function Contact() {
  const formRef = useRef<HTMLFormElement>(null);
  const [loading, setLoading] = React.useState(false);

  const SERVICE_ID = 'service_plxaxgk';
  const TEMPLATE_ID = 'template_yna0chf';
  const PUBLIC_KEY = 'E9W0PqDXtumAdOMsB';

  const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    if (formRef.current) {
      emailjs
        .sendForm(SERVICE_ID, TEMPLATE_ID, formRef.current, {
          publicKey: PUBLIC_KEY,
        })
        .then(
          () => {
            setLoading(false);
          },
          (error) => {
            setLoading(false);
          },
        );
    }
    e.currentTarget.reset();
  }

  return (
    <div className="py-20 text-center" id='contact'>
      <h2 className="text-3xl font-bold">Contact Us</h2>
      <form ref={formRef} onSubmit={sendEmail}
        className="mt-10 mx-auto w-1/2">
        <div className="mb-4">
          <TextField required name='user_name' label="nom et prenom" fullWidth />
        </div>
        <div className="mb-4">
          <TextField required name='user_email' label="E-mail" type="email" fullWidth />
        </div>
        <div className="mb-4">
          <TextField required name='subject' label="Objet" fullWidth />
        </div>
        <div className="mb-4">
          <TextField required name='message' label="Message" multiline rows={4} fullWidth />
        </div>
        <Box sx={{ m: 1, position: 'relative' }}>
          <Button
            type='submit' 
            variant="contained" 
            color="primary"
            disabled={loading}
          >
            Envoyer le message
          </Button>
          {loading && (
            <CircularProgress
              size={24}
              sx={{
                color: green[500],
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )}
        </Box>
      </form>
    </div>
  );
};
import React, { useState, useEffect } from 'react'
import { Box, Button } from '@mui/material';
import telecom from '../assets/telecom.png';
import reseau from '../assets/reseaux.png';
import mission_developper from '../assets/mission_developper.avif'

const slides = [
  {
    title: "Bienvenue chez Enou Consulting telecom",
    description: "Votre partenaire de confiance pour tous vos besoins en télécommunications",
    image: telecom,
  },
  {
    title: "Bienvenue chez Enou Consulting reseaux",
    description: "Votre partenaire de confiance pour tous vos besoins en réseaux informatiques",
    image: reseau,
  },
  {
    title: "Bienvenue chez Enou Consulting developpement web et mobile",
    description: "Votre partenaire de confiance pour tous vos besoins en développement web et mobile",
    image: mission_developper,
  },
];

export default function Header() {
  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 7000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Box id="home" position="relative" width="full" height="100vh" overflow="hidden">
      <Box
        display="flex"
        sx={{
          transition: 'transform 500ms ease-in-out',
          transform: `translateX(-${currentSlide * 100}%)`,
        }}
      >
        {slides.map((slide, index) => (
          <Box
            key={index}
            className="min-w-full h-screen flex items-center justify-center bg-black bg-cover bg-center"
            sx={{
              position: 'relative',
              textAlign: 'center',
            }}
          >
            <img src={slide.image} alt={slide.title} className='w-full h-full object-cover opacity-70'/>
            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white', zIndex: 1 }}>
              <h1 className="text-5xl font-bold">{slide.title}</h1>
              <p className="mt-4 text-xl">{slide.description}</p>
            </div>
          </Box>
        ))}
      </Box>
      <Button
        onClick={prevSlide}
        sx={{
          position: 'absolute',
          top: '50%',
          left: 0,
          transform: 'translateY(-50%)',
          padding: '10px',
          background: 'rgba(0, 0, 0, 0.5)',
          color: 'white',
        }}
      >
        &#10094;
      </Button>
      <Button
        onClick={nextSlide}
        sx={{
          position: 'absolute',
          top: '50%',
          right: 0,
          transform: 'translateY(-50%)',
          padding: '10px',
          background: 'rgba(0, 0, 0, 0.5)',
          color: 'white',
        }}
      >
        &#10095;
      </Button>
    </Box>
  );
};

import React from 'react'
import { Box, Typography, Link, IconButton, Container } from '@mui/material';
import { Facebook, Twitter, Instagram } from '@mui/icons-material';

export default function Footer() {
    return (
          <Box className="bg-gray-800 flex w-full justify-between text-white py-8 px-6">
            <Container sx={
              {
                width: '40%'
              }
            }>
            <Typography variant="h6" className='text-xxl'>© {new Date().getFullYear()} Enou Consulting</Typography>
            <Typography variant="body2">24 Rue des Iris 95100 Argenteuil</Typography>
              <Typography variant="body2">Tel: +33 7 81 67 89 94</Typography>
              <Typography variant="body2">E-mail: enouconsulting@gmail.com</Typography>
            </Container>
            <Container sx={
              {
                width: '30%'
              }
            } className='py-8'>
            <Typography variant="body2">•Designé et développé par Enou Consulting Cameroun</Typography>
            </Container>
            <Container sx={
              {
                width: '30%',
                textAlign: 'right'
              }
            } className="py-8">
              <IconButton component={Link} href="#" color="inherit" className="mr-2">
                <Facebook />
              </IconButton>
              <IconButton component={Link} href="#" color="inherit" className="mr-2">
                <Twitter />
              </IconButton>
              <IconButton component={Link} href="#" color="inherit" className="mr-2">
                <Instagram />
              </IconButton>
            </Container>
          </Box>
      );
    };
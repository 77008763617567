import * as React from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import { DetailItem, CarMissionModal } from './CarMissionModal';

type CardMissionProps = {
    title: string;
    subheader: string;
    image: string;
    description: DetailItem[];
    };

export default function CardMission(
    {title, subheader, image, description}: CardMissionProps
) {
  const [expanded, setExpanded] = React.useState(false);

  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardMedia
        component="img"
        height="194"
        image={image}
        alt={`photo de la mission sur ${title}`}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {subheader}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <Button variant='text' 
            onClick={() =>setExpanded(true)}>
            Plus de détails
        </Button>
      </CardActions>
      <CarMissionModal open={expanded} onClose={() =>setExpanded(false)} items={description} img={image} title={title} subheader={subheader} />
    </Card>
  );
}

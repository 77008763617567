import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, Button, IconButton, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import clsx from 'clsx';
import logo from '../assets/logo.jpg';

export default function Navbar() {
  const [scrolled, setScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      setScrolled(offset > 50);
    };

    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    handleResize(); // Vérifie la taille de l'écran initiale

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleMenuOpen = () => {
    setMenuOpen(true);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  return (
    <AppBar
      position="fixed"
      className={clsx('transition-all duration-300', {
        'bg-transparent': !scrolled,
        'bg-black': scrolled,
      })}
      elevation={0}
    >
      <Toolbar className="flex justify-between">
        <div className="flex items-center p-4">
          <a href="#home">
            <img src={logo} alt="logo" className="w-16 h-16 rounded-full mr-3" />
          </a>
          <Typography variant="h4" className="text-white ml-32">
            Enou Consulting
          </Typography>
        </div>

        {/* Affiche le menu déroulant sur les écrans <= 720px */}
        {isSmallScreen ? (
          <>
            <IconButton edge="end" color="inherit" aria-label="menu" onClick={handleMenuOpen}>
              <MenuIcon />
            </IconButton>

            <Menu
              anchorEl={null}
              open={menuOpen}
              onClose={handleMenuClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  marginTop: '2rem',
                  background: '#333',
                  color: 'white',
                },
              }}
            >
              <MenuItem onClick={handleMenuClose}>
                <a href="#about">à propos</a>
              </MenuItem>
              <MenuItem onClick={handleMenuClose}>
                <a href="#services">Services</a>
              </MenuItem>
              <MenuItem onClick={handleMenuClose}>
                <a href="#contact">nous contacter</a>
              </MenuItem>
            </Menu>
          </>
        ) : (
          // Affiche les boutons pour les écrans > 720px
          <div className="hidden md:flex space-x-4">
            <Button color="inherit">
              <a href="#about">à propos</a>
            </Button>
            <Button color="inherit">
              <a href="#services">Services</a>
            </Button>
            <Button color="inherit">
              <a href="#contact">nous contacter</a>
            </Button>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
}

import {
    Dialog,
    DialogTitle,
    Box,
    Button,
    DialogActions,
    DialogContent,
    Typography
} from "@mui/material";

export type DetailItem = {
    label: string;
    details: Array<string>;
}

export interface ModalProps {
    open: boolean;
    onClose: () => void;
    items: DetailItem[];
    img: string;
    title: string;
    subheader: string;
  }
  
export function CarMissionModal(props: ModalProps) {
    const { onClose, open, items, img, title, subheader } = props;
  
    const handleClose = () => {
      onClose();
    };

    return (
      <Dialog onClose={handleClose} open={open} maxWidth="md" fullWidth>
        <DialogTitle>Detail de la mission "{ title }"</DialogTitle>
        <DialogContent dividers>
          <Box className="w-full h-80 mb-4">
            <img src={img} alt="photo de la mission" className="object-cover w-full h-full rounded-md" />
          </Box>
          <Box className="overflow-y-auto max-h-80 p-4">
            <Typography variant="body1" className="text-lg font-bold">
              { subheader }
            </Typography>
          </Box>
          <Box className="">
            <ol className="list-decimal pl-4">
              {items.map((item, index) => (
                <li key={index} className="mb-2">
                  {item.label}:
                  <ul className="list-disc pl-4">
                    {item.details.map((detail, index) => (
                      <li key={index}>{detail}</li>
                    ))}
                  </ul>
                </li>
              ))}
            </ol>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">Close</Button>
        </DialogActions>
      </Dialog>
    );
  };
  
import React from 'react'
import { Box, Typography, Grid} from '@mui/material';
import mission_reseau from '../assets/mission_reseau.jpeg'
import mission_telecom from '../assets/mission_telecom.jpeg'
import mission_developper from '../assets/mission_developper.avif'
import CardMission from './cards/CardMission';

const missions = [
  {
    title: "Réseaux informatiques",
    subheader: "Les réseaux informatiques couvrent un vaste éventail de domaines d'expertise, essentiels pour le fonctionnement des infrastructures de communication modernes. Ces domaines nécessitent une expertise technique approfondie et une capacité constante à apprendre et à s'adapter aux technologies émergentes, car le domaine des réseaux informatiques évolue rapidement avec de nouvelles innovations technologiques.",
    image: mission_reseau,
    description: [
      {
        label: "Conception et architecture de réseaux",
        details: [
          "Développement de l'architecture réseau adaptée aux besoins spécifiques d'une organisation, incluant LAN (Local Area Network), WAN (Wide Area Network), et MAN (Metropolitan Area Network).",
          ],
      },
      {
        label: "Sécurité des réseaux",
        details: [
          "Mise en place de pare-feu, systèmes de détection et de prévention des intrusions (IDS/IPS), et technologies de chiffrement.",
          "Conception de politiques de sécurité, tests de pénétration et audits de sécurité pour protéger les données et les ressources réseau."
        ],
      },
      {
        label: "Virtualisation des réseaux",
        details: [
          "Implémentation de technologies SDN (Software Defined Networking) pour centraliser et simplifier la gestion des ressources réseau.",
          "Déploiement de solutions NFV (Network Functions Virtualization) pour virtualiser les fonctions réseau comme les routeurs, les pare-feu, et les équilibreurs de charge."
        ],
      },
      {
        label: "Cloud Networking",
        details: [
          "Conception et gestion de réseaux dans des environnements de cloud computing, incluant la connectivité IaaS (Infrastructure as a Service), PaaS (Platform as a Service), et SaaS (Software as a Service).",
          "Techniques de sécurisation des réseaux cloud, y compris les passerelles VPN et les configurations de réseau privé virtuel."
        ],
      },
      {
        label: "Protocoles de Communication",
        details: [
          "Maîtrise des protocoles de routage (BGP, OSPF, EIGRP) et de commutation.",
          "Compréhension des protocoles de niveau application tels que HTTP, SMTP, FTP, et autres protocoles de transfert de données."
        ],
      },
      {
        label: "Automatisation des Réseaux",
        details: [
          "Utilisation de scripts et de plateformes d'automatisation pour simplifier la gestion des configurations réseau, le déploiement et la maintenance.",
          "Implémentation de solutions basées sur l'intelligence artificielle pour l'analyse prédictive et la résolution proactive des problèmes"
        ],
      },
    ]
  },
  {
    title: "Télécommunications",
    subheader: "Les télécommunications sont un vaste domaine qui couvre de nombreux aspects de la technologie, de la gestion et de l'ingénierie. Ces domaines d'expertise peuvent se chevaucher et souvent les professionnels des télécommunications se spécialisent dans plusieurs de ces domaines afin de mieux répondre aux besoins intégrés et complexes de l'industrie.",
    image: mission_telecom,
    description: [
      {
        label: "Réseaux de communication",
        details: [
          "Conception, déploiement et gestion de réseaux fixes SDH, WDM et mobiles 4G, 5G.",
          "Technologies de réseau comme la fibre optique, le ADSL, la 5G, LTE, et le Wi-Fi.",
          "Installation et maintenance d'infrastructures physiques telles que les câbles Installation et maintenance d'infrastructures physiques telles que les câbles"
        ],
      },
      {
        label: "Interopérabilité et systèmes multi-fournisseurs",
        details: [
          "Intégration de matériel et de logiciels de différents fournisseurs pour créer des solutions réseau robustes et flexibles.",
          "Tests d'interopérabilité pour assurer la compatibilité entre divers équipements réseau.",
        ],
      },
      {
        label: "Ingénierie RF (Radiofréquence)",
        details: [
          "Planification, conception et optimisation de réseaux sans fil.",
          "Gestion des fréquences et interférences.",
        ],
      },
      {
        label: "Téléphonie IP et réseaux convergents",
        details: [
          "VoIP (Voice over Internet Protocol), SIP (Session Initiation Protocol).",
          "Intégration de services de données, voix et vidéo.",
        ],
      },
      {
        label: "Technologies émergentes et innovation",
        details: [
          "IoT (Internet of Things), ville intelligente, et technologies de maison connectée.",
        ],
      },
    ]
  },
  {
    title: "Développement WEB et Mobiles",
    subheader: "Le développement web et les applications mobiles sont des domaines cruciaux dans le secteur de la technologie, chacun ayant ses propres ensembles de compétences et d'expertises spécialisées. Ces expertises requièrent une veille technologique constante et une mise à jour continue des compétences pour rester pertinent dans un secteur qui évolue rapidement.",
    image: mission_developper,
    description: [
      {
        label: "Développement iOS",
        details: [
          "Programmation en Swift ou Objective-C.",
          "Utilisation de Xcode et connaissance approfondie des guidelines d'Apple et de l'écosystème iOS.",
        ],
      },
      {
        label: "Développement Android",
        details: [
          "Programmation en Java ou Kotlin.",
          "Maîtrise de l'environnement de développement Android Studio et des principes de Material Design."
        ],
      },
      {
        label: "Développement Cross-Platform",
        details: [
          "Utilisation de frameworks comme React Native et Flutter pour créer des applications qui fonctionnent à la fois sur iOS et Android.",
          "Capacité à maintenir la performance et l'esthétique native tout en réutilisant le code entre les plateformes."
        ],
      },
      {
        label: "UX/UI Design pour mobiles",
        details: [
          "Conception d'interfaces utilisateur et d'expériences utilisateur qui sont intuitives et efficaces sur les appareils mobiles.",
          "Utilisation de principes de design pour optimiser l'engagement et la facilité d'utilisation.",
        ],
      },
      {
        label: "Optimisation de Performance et Test",
        details: [
          "Tests de performance pour s'assurer que les applications fonctionnent bien sous diverses charges et conditions réseau.",
          "Déploiement de tests automatisés et manuels pour garantir la qualité et la fiabilité de l'application.",
        ],
      },
    ]
  },
];

export default function Missions() {
  return (
    <Box id='services'>
      <Typography variant='h2' className="text-3xl font-bold text-center p-3">Nos missions</Typography>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
          {missions.map((mission, index) => (
            <Grid item xs={4} sm={4} md={4} key={index}
              className="flex items-center justify-center p-10">
              <CardMission title={mission.title} subheader={mission.subheader} image={mission.image} description={mission.description} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};